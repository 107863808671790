<template>
  <div>
    <a-modal
        :width="900"
        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px'}"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form" @submit="handleOk">
        <a-row :gutter="24" type="flex" >
          <!-- Authors Table Column -->
          <a-col :span="12">
            <a-form-item label='Event Schedule Start Date & Time'>
              <a-date-picker
                  :disabled-date="disabledStartDate"
                  show-time
                  format="YYYY-MM-DD HH:mm"
                  placeholder="Start Date Time"
                  @openChange="handleStartOpenChange"
                  v-decorator="[

              'startDate',
                        { rules: [{ required: true, message: 'Start Date is required' }] ,
                                                initialValue:selectedItem.startDate,

              }
            ]"
              />

            </a-form-item>
          </a-col>

          <a-col :span="12" >
            <a-form-item label='End Date & Time'>
              <a-date-picker
                  :disabled-date="disabledEndDate"
                  show-time
                  format="YYYY-MM-DD HH:mm"
                  placeholder="End Date Time"
                  :open="endOpen"
                  @openChange="handleEndOpenChange"
                  v-decorator="[

              'endDate',
                        { rules: [{ required: true, message: 'End Date is required' }],
                        initialValue:selectedItem.endDate,
              }
            ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <div class="ant-page-header-heading-title">Event Type </div>

        </a-form-item>

        <a-form-item>
          <a-radio-group default-value="physical" button-style="solid" v-model="locationType">
            <a-radio-button value="physical">
              Physical Location
            </a-radio-button>
            <a-radio-button value="online">
              Online Event
            </a-radio-button>
            <a-radio-button value="not_announced">
              Not announced yet
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-row :gutter="24" type="flex" v-if="locationType=='physical'">

          <!-- Authors Table Column -->
          <a-col :span="12" >
            <a-form-item label='Venue Name'>
              <a-input
                  placeholder="Input a venue name"
                  v-decorator="[

              'venue',
                        { rules: [{ required: true, message: 'Venue Name is required' }] ,
                        initialValue:selectedItem?selectedItem.venue:'',
              }
            ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" >
            <a-form-item label='Street Address'>
              <a-input
                  placeholder="Input a descriptive address"
                  v-decorator="[

              'streetAddress',
                        { rules: [{ required: true, message: 'Street Address is required' }] ,
                        initialValue:selectedItem?selectedItem.streetAddress:'',
              }
            ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24" >
            <a-form-item label='Post Code'>
              <a-select
                  show-search
                  placeholder="Input Post Code"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="fetchPostCode"
                  @change="handleChange"
                  v-decorator="[

              'post_code_with_address',
                        { rules: [{ required: true, message: 'Post Code is required' }],
                        initialValue:post_code_with_address?post_code_with_address:undefined,
                         },

            ]"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in data" :key="d.value">
                  {{ d.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24" type="flex" v-if="locationType=='online'">

          <!-- Authors Table Column -->
          <a-col :span="24">
            <a-alert message="Online events do not include any physical location information. You will get to add online links in event details." type="info" show-icon />

          </a-col>
        </a-row>
        <a-row :gutter="24" type="flex" v-if="locationType=='not_announced'">

          <!-- Authors Table Column -->
          <a-col :span="24">
            <a-alert message="You can select this option if the type of event or venue of event has not been decided yet. However, you can edit this anytime." type="info" show-icon />

          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import debounce from 'lodash/debounce';

export default {
  props: ['editMode', 'visible','selectedItem','eventId'],
  data() {
    this.lastFetchId = 0;
    this.fetchPostCode = debounce(this.fetchPostCode, 800);
    return {
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:'',
      startDate:'',
      endDate:'',
      startValue: null,
      endValue: null,
      endOpen: false,
      lng:'',
      lat:'',
      district:'',
      country:'',
      ward:'',
      ccg:'',
      locationType:'physical',
      post_code_with_address:'',
      fetching: false,
      data:[]

      // visible: false,
    };
  },
  computed: {
    title() {
      return this.editMode ? 'Edit Event Schedule' : 'Create New Event Schedule'
    }
  },
  methods: {
    fetchPostCode(value) {
      if (value){
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.data = [];
        this.fetching = true;
        fetch('https://api.postcodes.io/postcodes/' + value + '/autocomplete')
            .then(response => response.json())
            .then(body => {
              if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
              }
              if (!body.result) {
                this.data = [{text:'No Post Code Found',value:''}];
                this.fetching = false;
                return;
              }
              const data = body.result.map(item => ({
                text: item,
                value: item,
              }));
              this.data = data;
              this.fetching = false;
            });
      }
      else {
        this.lng=this.lat=this.district=this.country=this.ward=this.ccg=this.post_code=''
      }
    },
    handleChange(value) {
      fetch('https://api.postcodes.io/postcodes/' + value)
          .then(response => response.json())
          .then(body => {
            this.lng=body.result.longitude
            this.lat=body.result.latitude
            this.country=body.result.country
            this.district=body.result.admin_district
            this.ward=body.result.ward
            this.ccg=body.result.ccg
            this.post_code=value
            const addr=value+', '+this.district+', '+this.country
            this.form.setFieldsValue({
              post_code_with_address: addr,
            });
            Object.assign(this, {
              data: [],
              fetching: false,
            });
          })

    },
    disabledStartDate(current) {
      return current && current < moment().startOf('day');
    },
    disabledEndDate(endValue) {
      const startValue = this.form.getFieldValue('startDate');
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
         this.confirmLoading = true
          values._id=this.selectedItem._id
          values.lng=this.lng
          values.lat=this.lat
          values.country=this.country
          values.district=this.district
          values.ward=this.ward
          values.ccg=this.ccg
          values.post_code=this.post_code
          values.locationType=this.locationType
          if (this.editMode){
            this.$store.dispatch("UPDATE_SCHEDULE", {form:values,event_id:this.eventId})
                .then((res) => {
                  this.fileList=[]
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          }
          else {
            this.$store.dispatch("CREATE_SCHEDULE", {form:values,event_id:this.eventId})
                .then((res) => {
                  this.fileList=[]
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },

    cancelForm(){
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
  },
  mounted() {
      if (Object.keys(this.selectedItem).length!=0){
        let data =this.selectedItem
        this.id=data._id
        this.lng=data.lng
        this.lat=data.lat
        this.country=data.country
        this.district=data.district
        this.ward=data.ward
        this.ccg=data.ccg
        this.post_code=data.post_code
        this.locationType=data.locationType
        if (this.locationType=='physical') {
          this.post_code_with_address = this.post_code + ', ' + this.district + ', ' + this.country
        }
    }
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
</style>